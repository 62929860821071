import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { getContact } from "../../services/api/apiHandler";
import { getTokenfromParams } from "../../utils/tokenHandler";
import { useSelector } from "react-redux";

// Squemas
const codeSquema = Yup.object().shape({
  code: Yup.string()
    .equals([process.env.REACT_APP_CODE], "Código incorrecto")
    .required("Este campo es obligatorio"),
});

const emailSquema = Yup.object().shape({
  email: Yup.string()
    .email("Debe ser formato email")
    .required("Este campo es obligatorio"),
});

const linksLogo = {
  advancing: "https://advancing.es",
  menai: "https://menai.capital",
};

// Page component
function GenerateLink() {
  const environmentData = useSelector((state) => state.environmentData);
  const [IsCodeOk, setIsCodeOk] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [magicLink, setMagicLink] = useState("");
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);

  const codeFormik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: codeSquema,
    onSubmit: (values, { resetForm }) => {
      resetForm();
      setIsCodeOk(true);
    },
  });

  const emailFormik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: emailSquema,
    onSubmit: (values, { resetForm }) => {
      setIsLoadingEmail(true);
      getContact(values.email, environmentData.business)
        .then((resp) => {
          if (resp.data.total === 0) {
            setIsLoadingEmail(false);
            alert("No existe un contacto con dicho email");
          } else {
            getTokenfromParams(values.email, resp.data.results[0].id)
              .then((token) => {
                setIsLoadingEmail(false);
                resetForm();
                setMagicLink(
                  `${window.location.origin}/process?token=${token}`
                );
                setIsGenerated(true);
              })
              .catch((error) => {
                setIsLoadingEmail(false);
                alert(error);
              });
          }
        })
        .catch((error) => {
          setIsLoadingEmail(false);
          alert(error);
        });
    },
  });

  return (
    <>
      <div className="flex flex-col h-full min-h-screen">
        <header className="flex items-center h-header min-h-header background-primary">
          <a href={linksLogo[environmentData.business]} className="ml-10 w-fit">
            <img
              className="w-40"
              src={`/assets/logos/${environmentData.business}/white.svg`}
              alt="logo"
            />
          </a>
        </header>
        <main className="flex-grow flex flex-row">
          <section className="md:w-6/12 px-12 2xl:px-40 py-10 2xl:py-32 w-full max-h-full">
            <div className="flex flex-col justify-between md:max-w-xl h-full">
              <div>
                <div className="mb-6">
                  {IsCodeOk ? (
                    isGenerated ? (
                      <>
                        <h1 className="primary-color font-700-24 mb-4">
                          Generar magic link
                        </h1>
                        <p className="mb-4 primary-color font-bold">
                          Acceso concedido
                        </p>
                        <p className="mb-4 primary-color font-bold">
                          Magic link generado correctamente
                        </p>
                        <p className="secondary-color font-bold">
                          <a
                            className="text-wrap"
                            href={magicLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {magicLink}
                          </a>
                        </p>
                      </>
                    ) : (
                      <>
                        <h1 className="primary-color font-700-24 mb-4">
                          Generar magic link
                        </h1>
                        <p className="mb-4 primary-color font-bold">
                          Acceso concedido
                        </p>
                        <p className="mb-4">
                          Generar un link con un correo específico
                        </p>
                      </>
                    )
                  ) : (
                    <h1 className="primary-color font-700-20 mb-4">
                      Introduce el código de acceso
                    </h1>
                  )}
                </div>
                {IsCodeOk ? (
                  !isGenerated && (
                    <form onSubmit={emailFormik.handleSubmit}>
                      <div className="mb-6 max-w-xs">
                        <input
                          type="text"
                          name="email"
                          id="email"
                          value={emailFormik.values.email}
                          placeholder="you@example.com"
                          className={
                            emailFormik.touched.email &&
                            emailFormik.errors.email
                              ? "border-2 px-3 py-2 leading-4 rounded-full text-base email-input w-full input-incorrect"
                              : "border-2 px-3 py-2 leading-4 rounded-full text-base email-input w-full"
                          }
                          onChange={emailFormik.handleChange}
                          onBlur={emailFormik.handleBlur}
                        />
                        {emailFormik.touched.email &&
                          emailFormik.errors.email && (
                            <div className="text-red-500 font-bold mt-1">
                              {emailFormik.errors.email}
                            </div>
                          )}
                      </div>
                      <button
                        type="submit"
                        className="min-w-button font-bold text-white px-3 py-2.5 rounded-full background-primary"
                      >
                        {isLoadingEmail ? (
                          <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        ) : (
                          "Generar"
                        )}
                      </button>
                    </form>
                  )
                ) : (
                  <form onSubmit={codeFormik.handleSubmit}>
                    <div className="mb-6 max-w-xs">
                      <input
                        type="password"
                        name="code"
                        id="code"
                        value={codeFormik.values.code}
                        placeholder="Código"
                        className={
                          codeFormik.touched.code && codeFormik.errors.code
                            ? "border-2 px-3 py-2 leading-4 rounded-full text-base email-input w-full input-incorrect"
                            : "border-2 px-3 py-2 leading-4 rounded-full text-base email-input w-full"
                        }
                        onChange={codeFormik.handleChange}
                        onBlur={codeFormik.handleBlur}
                      />
                      {codeFormik.touched.code && codeFormik.errors.code && (
                        <div className="text-red-500 font-bold mt-1">
                          {codeFormik.errors.code}
                        </div>
                      )}
                    </div>
                    <button
                      type="submit"
                      className="min-w-button font-bold text-white px-3 py-2.5 rounded-full background-primary"
                    >
                      Acceder
                    </button>
                  </form>
                )}
              </div>
              <div className="mt-8 mx-auto max-w-2xl">
                <p className="primary-color text-12 leading-4 mb-4 flex justify-center text-center">
                  Tu información personal será tratada de forma segura y
                  confidencial
                </p>
                <p className="text-grey text-12 leading-4 text-center w-full">
                  <a
                    className="mr-1"
                    href="https://www.advancing.es/aviso-legal/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Aviso Legal
                  </a>
                  ·
                  <a
                    className="mr-1 ml-1"
                    href="https://www.advancing.es/politica-privacidad/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Política de privacidad
                  </a>
                  ·
                  <a
                    className="ml-1"
                    href="https://www.advancing.es/cookies-banner-de-cookies/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Política de cookies
                  </a>
                </p>
              </div>
            </div>
          </section>
          <section className="md:w-6/12 hidden md:block h-fix">
            <div className="w-full h-full">
              <img
                className="w-full h-full object-cover"
                src="/assets/generatelink/background-image.jpg"
                alt="coding"
              />
            </div>
          </section>
        </main>
      </div>
    </>
  );
}

export default GenerateLink;

export default function rotate(srcBase64, degrees, callback) {
  const canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");
  let image = new Image();

  image.onload = function () {
    canvas.width = degrees % 180 === 0 ? image.width : image.height;
    canvas.height = degrees % 180 === 0 ? image.height : image.width;

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((degrees * Math.PI) / 180);
    ctx.drawImage(image, image.width / -2, image.height / -2);

    callback(canvas.toDataURL("image/jpeg", 1.0));
  };

  image.src = srcBase64;
}

// Libraries
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import Header from "../../components/Header";
import Button from "../../components/Button";

// Redux
import { importSelfieImage } from "../../redux/globalData/actions";

// Hooks
import { useNavigation } from "../../hooks/useNavigation";

// Mitek service
import { takePhotoMitek } from "../../services/mitek/mitekServiceHandler";

// Constants
import { SELFIE_TYPE } from "../../constants/mitekConstants";

function SelfieAdvice() {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const environmentData = useSelector((state) => state.environmentData);

  const [loading, setLoading] = useState(false);

  function nextPage() {
    setLoading(true);
    takePhotoMitek(
      SELFIE_TYPE,
      (result) => {
        setLoading(false);
        dispatch(importSelfieImage(result.response.imageData));
        navigation("/selfie-result");
      },
      () => {
        setLoading(false);
      },
      (error) => {
        alert("Error al tomar la imagen. Más detalle en la consola.");
        console.log(error);
      }
    );
  }

  return (
    <div className="flex flex-col justify-between items-center w-screen h-full">
      <Header index="3 / 3" backURL="/back-result" />
      <div className="flex flex-col grow items-center w-screen">
        <div className="w-10/12 font-400-20 primary-color mt-8">
          Para finalizar,{" "}
          <span className="font-700-22 underline">hazte una foto</span> situando
          la cara en medio del óvalo.
        </div>
        <img
          className="w-6/12 mt-3"
          alt="back-gif"
          src="/assets/animations/selfie.gif"
        ></img>
        <div className="w-10/12 mt-5 mb-8">
          <div className="font-700-16 primary-color">Sigue estos consejos</div>
          <div className="mt-2">
            <div className="flex mt-3">
              <img
                className="mr-3"
                alt="icon-5"
                src={`/assets/icons/${environmentData.business}/advice-icon-5.svg`}
              />
              <div className="font-400-15 text-secondary-color">
                No cubras tu cabeza.
              </div>
            </div>
            <div className="flex mt-3">
              <img
                className="mr-3"
                alt="icon-6"
                src={`/assets/icons/${environmentData.business}/advice-icon-6.svg`}
              />
              <div className="font-400-15 text-secondary-color">
                No cubras tu cara.
              </div>
            </div>
            <div className="flex mt-3">
              <img
                className="mr-3"
                alt="icon-7"
                src={`/assets/icons/${environmentData.business}/advice-icon-7.svg`}
              />
              <div className="font-400-15 text-secondary-color">
                No uses gafas de sol.
              </div>
            </div>
            <div className="flex mt-3">
              <img
                className="mr-3"
                alt="icon-8"
                src={`/assets/icons/${environmentData.business}/advice-icon-8.svg`}
              />
              <div className="font-400-15 text-secondary-color">
                Sonríe cuando se te pida.
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <Button className="mb-6" text="Siguiente" />
      ) : (
        <Button className="mb-6" text="Siguiente" onClick={nextPage} />
      )}
    </div>
  );
}

export default SelfieAdvice;

import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { setCode } from "../../redux/isCodeOk/actions";
import MitekTable from "../../components/MitekTable";
import TinkTable from "../../components/TinkTable";

// Squema
const codeSquema = Yup.object().shape({
  code: Yup.string()
    .equals([process.env.REACT_APP_CODE], "Código incorrecto")
    .required("Este campo es obligatorio"),
});

function AdminPanel() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const isCodeOk = useSelector((state) => state.isCodeOk);
  const environmentData = useSelector((state) => state.environmentData);
  const [service, setService] = useState("mitek");
  const [page, setPage] = useState(1);

  const codeFormik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: codeSquema,
    onSubmit: (values, { resetForm }) => {
      resetForm();
      dispatch(setCode(true));
      configTable();
    },
  });

  function configTable() {
    const page = searchParams.get("dashboard_page") || 1;
    const service = searchParams.get("service") || "mitek";
    setPage(page);
    setService(service);
  }

  useEffect(() => {
    if (isCodeOk) {
      configTable();
    }
    // eslint-disable-next-line
  }, [searchParams]);

  if (!isCodeOk) {
    return (
      <div className="w-screen h-screen flex flex-col items-center bg-slate-50">
        <div className="w-72 mt-16 mb-16">
          <img
            className="w-full"
            alt="logo"
            src={`/assets/logos/${environmentData.business}/primary.svg`}
          />
        </div>
        <div className="flex flex-col items-center px-20 py-14 rounded-3xl border border-slate-300 login-box bg-white">
          <h1 className="font-700-22 primary-color mb-7">
            Introduce el código de acceso
          </h1>
          <form
            className="flex flex-col items-center w-full"
            onSubmit={codeFormik.handleSubmit}
          >
            <input
              type="password"
              name="code"
              id="code"
              value={codeFormik.values.code}
              placeholder="Código"
              className={
                codeFormik.touched.code && codeFormik.errors.code
                  ? "border-2 px-3 py-2 leading-4 rounded-full text-base email-input w-full input-incorrect"
                  : "border-2 px-3 py-2 leading-4 rounded-full text-base email-input w-full"
              }
              onChange={codeFormik.handleChange}
              onBlur={codeFormik.handleBlur}
            />
            {codeFormik.touched.code && codeFormik.errors.code && (
              <div className="text-red-500 font-bold mt-1">
                {codeFormik.errors.code}
              </div>
            )}
            <button
              type="submit"
              className="min-w-button font-bold text-white px-3 py-2.5 rounded-full background-primary mt-7"
            >
              Acceder
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen w-full h-full flex flex-col bg-slate-50">
      <div className="m-16">
        <div className="flex flex-row justify-between mb-10">
          <div>
            <img
              className="w-64"
              alt="logo"
              src={`/assets/logos/${environmentData.business}/primary.svg`}
            />
          </div>
          {/* <div>Buscador</div> */}
        </div>
        <div
          className="flex flex-row rounded-md dashboard bg-white border border-slate-400 w-fit mb-2 overflow-hidden cursor-pointer"
          onClick={() => {
            if (service === "mitek")
              navigate(`/admin?dashboard_page=1&service=tink`);
            else navigate(`/admin?dashboard_page=1&service=mitek`);
          }}
        >
          <div
            className={`px-3 py-1 border-r border-slate-400 ${
              service === "mitek"
                ? "background-primary text-white font-bold"
                : "primary-color"
            }`}
          >
            Mitek
          </div>
          <div
            className={`px-3 py-1 ${
              service === "tink"
                ? "background-primary text-white font-bold"
                : "primary-color"
            }`}
          >
            Tink
          </div>
        </div>
        {service === "mitek" ? (
          <MitekTable page={page} business={environmentData.business} />
        ) : (
          <TinkTable page={page} business={environmentData.business} />
        )}
      </div>
    </div>
  );
}

export default AdminPanel;

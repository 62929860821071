// Libraries
import { useNavigate, useSearchParams } from "react-router-dom";

export function useNavigation() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = searchParams.get("token") || "no-token";

  return (route) => {
    navigate({
      pathname: `/process${route}`,
      search: `?token=${token}`,
    });
  };
}

// Libraries
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Hooks
import { useNavigation } from "../../hooks/useNavigation";

// Redux
import { setFinished } from "../../redux/globalData/actions";

// Api
import { sendToVendeptMitekMiddleware } from "../../services/api/apiHandler";

function Loading() {
  const globalData = useSelector((state) => state.globalData);
  const environmentData = useSelector((state) => state.environmentData);
  const dispatch = useDispatch();
  const navigation = useNavigation();

  useEffect(() => {
    if (globalData.isFinished) {
      dispatch(setFinished(false));
      navigation("/welcome");
      return;
    }

    if (globalData.email === "no-email") {
      alert(
        "No se han cargado tus datos correctamente.\nVuelve a intentarlo desde la plataforma."
      );
      navigation("/logo");
    } else {
      const dataToSend = {
        hubspotId: globalData.hubspotId,
        email: globalData.email,
        frontDocImage: globalData.images.frontDoc.replace(
          "data:image/jpeg;base64,",
          ""
        ),
        backDocImage: globalData.images.backDoc.replace(
          "data:image/jpeg;base64,",
          ""
        ),
        selfieImage: globalData.images.selfie.replace(
          "data:image/jpeg;base64,",
          ""
        ),
      };

      sendToVendeptMitekMiddleware(dataToSend, environmentData.business)
        .then(() => {
          dispatch(setFinished(true));
          navigation("/thank-you");
        })
        .catch((err) => {
          alert("Error al enviar los datos. Por favor, vuelve a intentarlo.");
          navigation("/logo");
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col justify-evenly items-center w-screen h-full pb-28">
      <img
        alt="logo"
        src={`/assets/logos/${environmentData.business}/primary.svg`}
      />
      <div className="flex flex-col items-center">
        <div className="primary-color font-700-24">Enviando datos</div>
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default Loading;

// Libraries
import * as mitekScienceSDK from "./mitek-science-sdk";

// Constants
import { SELFIE_TYPE } from "../../constants/mitekConstants";

const captureMessages = {
  MISNAP_HEAD_OUTSIDE: "Alinea tu cara en el óvalo",
  MISNAP_HEAD_SKEWED: "Mira hacia la cámara",
  MISNAP_AXIS_ANGLE: "Pon el teléfono vertical",
  MISNAP_HEAD_TOO_CLOSE: "Aléjate de la cámara",
  MISNAP_HEAD_TOO_FAR: "Acércate a la cámara",
  MISNAP_STAY_STILL: "Mantente quieto",
  MISNAP_SUCCESS: "Captura correcta",
  MISNAP_STOP_SMILING: "Deja de sonreír",
  MISNAP_SMILE: "Sonríe",
  MISNAP_READY_POSE: "Mantenlo ahí",
  MITEK_ERROR_GLARE: "Hay demasiados reflejos",
  NO_FACE_FOUND: "Cara no encontrada",
  MITEK_ERROR_FOUR_CORNER: "Documento no encontrado",
  MITEK_ERROR_TOO_DARK: "Demasiado oscuro. Usa buena iluminación",
  MITEK_ERROR_FOCUS: "Manten estable el objetivo",
  MITEK_ERROR_TOO_FAR: "Acerca el documento",
  MITEK_ERROR_TOO_CLOSE: "Aleja el documento",
  MITEK_ERROR_NOT_CENTERED: "Centra el documento",
  MITEK_ERROR_MIN_PADDING: "Aleja el documento",
  MITEK_ERROR_HORIZONTAL_FILL: "Acerca el documento",
  MITEK_ERROR_LOW_CONTRAST: "Centra el documento en un fondo oscuro",
  MITEK_ERROR_BUSY_BACKGROUND: "Centra el documento en un fondo plano",
  MITEK_ERROR_SKEW_ANGLE: "Pon el documento recto (demasiado ángulo)",
};

function setMessageWrapperStyle(type) {
  const messageWrapperStyle = document.getElementById("mitekHintMessage").style;
  messageWrapperStyle.position = "absolute";
  if (type === SELFIE_TYPE) {
    messageWrapperStyle.top = "20px";
  } else {
    messageWrapperStyle.bottom = "20px";
  }
  messageWrapperStyle.fontFamily = "'Roboto', sans-serif";
  messageWrapperStyle.fontWeight = "700";
  messageWrapperStyle.fontSize = "18px";
  messageWrapperStyle.border = "none";
  messageWrapperStyle.background = "none";
  messageWrapperStyle.color = "#FFFFFF";
  messageWrapperStyle.textShadow = "0px 1px 5px #000000";
  messageWrapperStyle.opacity = "1";
}

function setBackButton(stopFunction) {
  const buttonElement = document.createElement("div");
  buttonElement.setAttribute(
    "class",
    "flex flex-row absolute top-4 left-8 text-white font-700-14 cursor-pointer"
  );
  buttonElement.addEventListener("click", () => {
    mitekScienceSDK.cmd("SDK_STOP");
    stopFunction();
  });

  const backIcon = document.createElement("img");
  backIcon.setAttribute("alt", "back-icon");
  backIcon.setAttribute("src", "/assets/icons/back-icon.svg");
  buttonElement.appendChild(backIcon);

  const backText = document.createElement("div");
  backText.setAttribute("class", "ml-1");
  backText.innerText = "Volver";
  buttonElement.appendChild(backText);

  const displayContainer = document.getElementById("mitekDisplayContainer");
  displayContainer.appendChild(buttonElement);
}

export function takePhotoMitek(
  type,
  resultFunction,
  stopFunction,
  errorFunction,
  side = "no-side"
) {
  mitekScienceSDK.on("CAMERA_DISPLAY_STARTED", function () {
    const mitekMediaStreamSource = document.getElementById(
      "mitekMediaStreamSource"
    );
    if (mitekMediaStreamSource.paused) {
      mitekMediaStreamSource.play();
    }

    setMessageWrapperStyle(type);
    setBackButton(stopFunction);

    setTimeout(() => {
      if (side === "front") {
        document
          .getElementsByClassName("integrator DL_FRONT")[0]
          .classList.add("FRONT");
      } else if (side === "back") {
        document
          .getElementsByClassName("integrator DL_FRONT")[0]
          .classList.add("BACK");
      }
    }, "150");
  });

  mitekScienceSDK.on("FRAME_PROCESSING_FEEDBACK", (status) => {
    if (status.key !== null) {
      mitekScienceSDK.cmd("SHOW_HINT", captureMessages[status.key]);

      if (type === SELFIE_TYPE) {
        if (
          status.key === "MISNAP_SMILE" ||
          status.key === "MISNAP_STOP_SMILING" ||
          status.key === "MISNAP_READY_POSE"
        ) {
          document
            .getElementsByClassName("integrator SELFIE")[0]
            .classList.add("FACE_IN_GUIDE");
        } else {
          document
            .getElementsByClassName("integrator SELFIE")[0]
            .classList.remove("FACE_IN_GUIDE");
        }
      }
    }
  });

  mitekScienceSDK.on("FRAME_CAPTURE_RESULT", (result) => {
    mitekScienceSDK.cmd("SDK_STOP");
    resultFunction(result);
  });

  mitekScienceSDK.on("SDK_ERROR", (error) => {
    stopFunction();
    errorFunction(error);
  });

  mitekScienceSDK.cmd("CAPTURE_AND_PROCESS_FRAME", {
    mode: "AUTO_CAPTURE",
    documentType: type,
    mitekSDKPath: "/mitekSDK/",
    options: {
      qualityPercent: 80,
      guidePaddingLevel: 1,
    },
  });
}

import initialState from "./state";
import { SET_CODE } from "./types";

const isCodeOkReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CODE:
      return action.payload;
    default:
      return state;
  }
};

export default isCodeOkReducer;

// Libraries
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Pages
import LogoPage from "./pages/LogoPage";
import Welcome from "./pages/Welcome";
import FrontAdvice from "./pages/FrontAdvice";
import FrontResult from "./pages/FrontResult";
import BackAdvice from "./pages/BackAdvice";
import BackResult from "./pages/BackResult";
import SelfieAdvice from "./pages/SelfieAdvice";
import SelfieResult from "./pages/SelfieResult";
import Loading from "./pages/Loading";
import ThankYou from "./pages/ThankYou";
import GenerateLink from "./pages/GenerateLink";
import AdminPanel from "./pages/AdminPanel";
import LogDetail from "./pages/LogDetail";
import LogDetailTink from "./pages/LogDetailTink";

// Redux
import { setWhiteLabelData } from "./redux/globalData/actions";
import { setEnvironmentData } from "./redux/envData/actions";

// Hooks
import { useNavigation } from "./hooks/useNavigation";

// Utils
import {
  getBusinessAndEnvironment,
  setHtmlTitle,
  setPropertiesPerBusiness,
} from "./utils/businessProperties";
import { getParamsfromToken } from "./utils/tokenHandler";

// General styles
import "./App.css";

function App() {
  const [searchParams] = useSearchParams();

  const globalData = useSelector((state) => state.globalData);

  const navigation = useNavigation();
  const dispatch = useDispatch();

  useEffect(() => {
    const initialInfo = getBusinessAndEnvironment();
    setPropertiesPerBusiness(initialInfo.business);
    setHtmlTitle(initialInfo);
    dispatch(setEnvironmentData(initialInfo));

    if (initialInfo.environment === "kyc-process") {
      if (!globalData.isFilled) {
        document.getElementById("root").style.height =
          window.innerHeight + "px";

        navigation("/logo");

        const token = searchParams.get("token") || "no-token";
        const dataDecoded = getParamsfromToken(token);

        dispatch(
          setWhiteLabelData({
            hubspotId: dataDecoded.hubspotId,
            email: dataDecoded.email,
            isFilled: true,
          })
        );

        setTimeout(() => {
          navigation("/welcome");
        }, 3000);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Routes>
        <Route exact path="/" element={<Navigate to="/generate-link" />} />
        <Route path="/process/logo" element={<LogoPage />} />
        <Route path="/process/welcome" element={<Welcome />} />
        <Route path="/process/front-advice" element={<FrontAdvice />} />
        <Route path="/process/front-result" element={<FrontResult />} />
        <Route path="/process/back-advice" element={<BackAdvice />} />
        <Route path="/process/back-result" element={<BackResult />} />
        <Route path="/process/selfie-advice" element={<SelfieAdvice />} />
        <Route path="/process/selfie-result" element={<SelfieResult />} />
        <Route path="/process/loading" element={<Loading />} />
        <Route path="/process/thank-you" element={<ThankYou />} />
        <Route path="/generate-link" element={<GenerateLink />} />
        <Route path="/admin" element={<AdminPanel />} />
        <Route path="/admin/:logId" element={<LogDetail />} />
        <Route path="/admin/bank/:logId" element={<LogDetailTink />} />
      </Routes>
    </>
  );
}

export default App;

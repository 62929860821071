import { combineReducers } from "redux";
import globalDataReducer from "./globalData/reducer";
import isCodeOkReducer from "./isCodeOk/reducer";
import environmentDataReducer from "./envData/reducer";

const reducers = combineReducers({
  globalData: globalDataReducer,
  isCodeOk: isCodeOkReducer,
  environmentData: environmentDataReducer,
});

export default reducers;

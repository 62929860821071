import jwt_decode from "jwt-decode";
import * as jose from "jose";

export function getParamsfromToken(token) {
  let dataDecoded = {};

  try {
    if (token !== "no-token") {
      dataDecoded = jwt_decode(token);
    } else {
      alert("No hay token");
      dataDecoded = {
        business: "no-business",
        hubspotId: "no-hubspot-id",
        email: "no-email",
      };
    }
  } catch (error) {
    alert("Token incorrecto");
    console.log(error);
    dataDecoded = {
      business: "no-business",
      hubspotId: "no-hubspot-id",
      email: "no-email",
    };
  }

  return dataDecoded;
}

export async function getTokenfromParams(email, contactId) {
  const token = await new jose.UnsecuredJWT({
    email: email,
    hubspotId: contactId,
  }).encode();

  return token;
}

import {
  SET_WHITELABEL_DATA,
  IMPORT_FRONT_DOC_IMAGE,
  IMPORT_BACK_DOC_IMAGE,
  IMPORT_SELFIE_IMAGE,
  SET_FINISHED,
} from "./types";

export const setWhiteLabelData = (data) => ({
  type: SET_WHITELABEL_DATA,
  payload: data,
});

export const importFrontDocImage = (data) => ({
  type: IMPORT_FRONT_DOC_IMAGE,
  payload: data,
});

export const importBackDocImage = (data) => ({
  type: IMPORT_BACK_DOC_IMAGE,
  payload: data,
});

export const importSelfieImage = (data) => ({
  type: IMPORT_SELFIE_IMAGE,
  payload: data,
});

export const setFinished = (data) => ({
  type: SET_FINISHED,
  payload: data,
});

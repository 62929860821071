/**
 * Return the data to set the properties.
 * @returns initial data: business and environment.
 */
export function getBusinessAndEnvironment() {
  const data = {
    business: "",
    environment: "",
  };

  switch (window.location.hostname) {
    case process.env.REACT_APP_HOSTNAME_ADVANCING:
      data.business = "advancing";
      break;
    case process.env.REACT_APP_HOSTNAME_MENAI:
      data.business = "menai";
      break;
    default:
      data.business = "advancing";
      break;
  }

  if (window.location.pathname === "/generate-link") {
    data.environment = "generate-link";
  } else if (window.location.pathname.startsWith("/admin")) {
    data.environment = "panel-admin";
  } else if (window.location.pathname.startsWith("/process")) {
    data.environment = "kyc-process";
  } else if (window.location.pathname === "/") {
    data.environment = "generate-link";
  } else {
    data.environment = "no-environment";
  }

  return data;
}

/**
 * Set the primary color, secondary color and favicon.
 * @param business could be "advancing" and "menai".
 */
export function setPropertiesPerBusiness(business) {
  const properties = {
    primaryColor: "",
    secondaryColor: "",
    fontFamily: "",
    favicon: "",
  };

  switch (business) {
    case "advancing":
      properties.primaryColor = "#02005C";
      properties.secondaryColor = "#6CF0B9";
      properties.fontFamily = "Mulish, sans-serif";
      properties.favicon = "/assets/logos/advancing/favicon.png";
      // properties.docTitle = "Validación ID - Advancing";
      break;
    case "menai":
      properties.primaryColor = "#1A1A1A";
      properties.secondaryColor = "#C8FF00";
      properties.fontFamily = "Nunito Sans, sans-serif";
      properties.favicon = "/assets/logos/menai/favicon.png";
      // properties.docTitle = "Validación ID - Menai";
      break;
    default:
      properties.primaryColor = "#000000";
      properties.secondaryColor = "#868686";
      properties.fontFamily = "Arial, sans-serif";
      properties.favicon = "/favicon.ico";
      // properties.docTitle = "Validación ID";
      break;
  }

  document.documentElement.style.setProperty(
    "--primary-color",
    properties.primaryColor
  );
  document.documentElement.style.setProperty(
    "--secondary-color",
    properties.secondaryColor
  );
  document.documentElement.style.setProperty(
    "--secondary-color-opacity",
    properties.secondaryColor + "80"
  );
  document.documentElement.style.setProperty(
    "--font-family",
    properties.fontFamily
  );
  document.querySelector("link[rel~='icon']").href = properties.favicon;
}

/**
 * Set the html title.
 * @param data is the information necessary to set the html title: business and environment.
 */
export function setHtmlTitle(data) {
  let title;

  switch (data.environment) {
    case "generate-link":
      title = `Generador de link - ${data.business}`;
      break;
    case "panel-admin":
      title = `Administrador - ${data.business}`;
      break;
    case "kyc-process":
      title = `Validación ID - ${data.business}`;
      break;
    default:
      title = "Error de título";
      break;
  }

  document.querySelector("title").innerText = title;
}

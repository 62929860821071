// Libraries
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import Header from "../../components/Header";
import Button from "../../components/Button";

// Redux
import { importFrontDocImage } from "../../redux/globalData/actions";

// Hooks
import { useNavigation } from "../../hooks/useNavigation";

// Mitek service
import { takePhotoMitek } from "../../services/mitek/mitekServiceHandler";

// Utils
import rotate from "../../utils/rotateImage";

// Constants
import { DOCUMENT_TYPE } from "../../constants/mitekConstants";

function FrontResult() {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const globalData = useSelector((state) => state.globalData);
  const [loading, setLoading] = useState(false);

  function nextPage() {
    navigation("/back-advice");
  }

  function tryAgain() {
    setLoading(true);
    takePhotoMitek(
      DOCUMENT_TYPE,
      (result) => {
        setLoading(false);
        const fullWidth = window.innerWidth;
        const fullHeight = window.innerHeight;
        if (fullWidth < fullHeight) {
          rotate(result.response.imageData, -90, (imageRotated) => {
            dispatch(importFrontDocImage(imageRotated));
          });
        } else {
          dispatch(importFrontDocImage(result.response.imageData));
        }
      },
      () => {
        setLoading(false);
      },
      (error) => {
        alert("Error al tomar la imagen. Más detalle en la consola.");
        console.log(error);
      },
      "front"
    );
  }

  return (
    <div className="flex flex-col justify-between items-center w-screen h-full">
      <Header index="1 / 3" />
      <div className="flex flex-col grow justify-evenly w-10/12">
        <div className="font-700-24 primary-color mt-4">Tu captura</div>
        <div className="flex justify-center mt-4 max-h-60">
          <img
            className="object-contain"
            alt="front-result"
            src={globalData.images.frontDoc}
          />
        </div>
        <div className="text-secondary-color font-400-15 mt-4 mb-4">
          Antes de confirmar, comprueba que los datos son legibles y el
          documento está enfocado y sin brillos.
        </div>
      </div>
      {loading ? (
        <>
          <Button className="mb-6" text="Confimar" />
          <div className="font-400-18 primary-color mb-6 cursor-pointer">
            Volver a intentarlo
          </div>
        </>
      ) : (
        <>
          <Button className="mb-6" text="Confimar" onClick={nextPage} />
          <div
            className="font-400-18 primary-color mb-6 cursor-pointer"
            onClick={tryAgain}
          >
            Volver a intentarlo
          </div>
        </>
      )}
    </div>
  );
}

export default FrontResult;

// Libraries
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import Header from "../../components/Header";
import Button from "../../components/Button";

// Redux
import { importSelfieImage } from "../../redux/globalData/actions";

// Hooks
import { useNavigation } from "../../hooks/useNavigation";

// Mitek service
import { takePhotoMitek } from "../../services/mitek/mitekServiceHandler";

// Constants
import { SELFIE_TYPE } from "../../constants/mitekConstants";

function SelfieResult() {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const globalData = useSelector((state) => state.globalData);
  const [loading, setLoading] = useState(false);

  function nextPage() {
    navigation("/loading");
  }

  function tryAgain() {
    setLoading(true);
    takePhotoMitek(
      SELFIE_TYPE,
      (result) => {
        setLoading(false);
        dispatch(importSelfieImage(result.response.imageData));
      },
      () => {
        setLoading(false);
      },
      (error) => {
        alert("Error al tomar la imagen. Más detalle en la consola.");
        console.log(error);
      }
    );
  }

  return (
    <div className="flex flex-col justify-between items-center w-screen h-full">
      <Header index="3 / 3" backURL="/selfie-advice" />
      <div className="flex flex-col grow justify-evenly w-10/12">
        <div className="font-700-24 primary-color mt-4">Tu selfie</div>
        <div className="text-secondary-color font-400-15 mt-4">
          Confirma que tu cara esté enfocada y descubierta.
        </div>
        <div className="flex justify-center mt-4 mb-4 max-h-custom">
          <img
            className="object-contain"
            alt="selfie-result"
            src={globalData.images.selfie}
          />
        </div>
      </div>
      {loading ? (
        <>
          <Button className="mb-6" text="Confimar" />
          <div className="font-400-18 primary-color mb-6 cursor-pointer">
            Volver a intentarlo
          </div>
        </>
      ) : (
        <>
          <Button className="mb-6" text="Confimar" onClick={nextPage} />
          <div
            className="font-400-18 primary-color mb-6 cursor-pointer"
            onClick={tryAgain}
          >
            Volver a intentarlo
          </div>
        </>
      )}
    </div>
  );
}

export default SelfieResult;

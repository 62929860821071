import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { getLogByIdTink } from "../../services/api/apiHandler";
import { setCode } from "../../redux/isCodeOk/actions";

// Squema
const codeSquema = Yup.object().shape({
  code: Yup.string()
    .equals([process.env.REACT_APP_CODE], "Código incorrecto")
    .required("Este campo es obligatorio"),
});

// Error messages
const errorMessages = {
  BAD_REQUEST: "Ha ocurrido un error en la generación de la solicitud.",
  USER_CANCELLED: "El usuario ha cancelado la solicitud.",
  AUTHENTICATION_ERROR:
    "El usuario no ha podido autenticarse correctamente con su banco.",
  INTERNAL_ERROR: "Un error interno ha ocurrido.",
  TEMPORARY_ERROR: "Un error inesperado ha ocurrido.",
};

function LogDetailTink() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state: locationState } = useLocation();
  const { logId } = useParams();
  const isCodeOk = useSelector((state) => state.isCodeOk);
  const environmentData = useSelector((state) => state.environmentData);
  const [logInformation, setLogInformation] = useState({
    loaded: false,
    data: [],
  });

  const codeFormik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: codeSquema,
    onSubmit: (values, { resetForm }) => {
      resetForm();
      dispatch(setCode(true));
      getLogByIdTink(logId, environmentData.business)
        .then(({ data }) => {
          setLogInformation({ loaded: true, data: data });
        })
        .catch((error) => {
          if (error.response.data.isEmpty) {
            alert("No existe este registro");
            navigate("/admin?service=tink");
          } else {
            alert("Error al tomar los datos del servidor");
            console.log(error);
          }
        });
    },
  });

  function backToDashboard() {
    if (locationState) {
      navigate(
        `/admin?dashboard_page=${locationState.pageToBack}&service=tink`
      );
    } else {
      navigate(`/admin?service=tink`);
    }
  }

  useEffect(() => {
    let isSubscribed = true;

    if (isCodeOk) {
      getLogByIdTink(logId, environmentData.business)
        .then(({ data }) => {
          if (isSubscribed) {
            setLogInformation({ loaded: true, data: data });
          }
        })
        .catch((error) => {
          if (isSubscribed) {
            if (error.response.data.isEmpty) {
              alert("No existe este registro");
              navigate("/admin?service=tink");
            } else {
              alert("Error al tomar los datos del servidor");
              console.log(error);
            }
          }
        });
    }

    return () => (isSubscribed = false);
    // eslint-disable-next-line
  }, []);

  if (!isCodeOk) {
    return (
      <div className="w-screen h-screen flex flex-col items-center bg-slate-50">
        <div className="w-72 mt-16 mb-16">
          <img
            className="w-full"
            alt="logo"
            src={`/assets/logos/${environmentData.business}/primary.svg`}
          />
        </div>
        <div className="flex flex-col items-center px-20 py-14 rounded-3xl border border-slate-300 login-box bg-white">
          <h1 className="font-700-22 primary-color mb-7">
            Introduce el código de acceso
          </h1>
          <form
            className="flex flex-col items-center w-full"
            onSubmit={codeFormik.handleSubmit}
          >
            <input
              type="password"
              name="code"
              id="code"
              value={codeFormik.values.code}
              placeholder="Código"
              className={
                codeFormik.touched.code && codeFormik.errors.code
                  ? "border-2 px-3 py-2 leading-4 rounded-full text-base email-input w-full input-incorrect"
                  : "border-2 px-3 py-2 leading-4 rounded-full text-base email-input w-full"
              }
              onChange={codeFormik.handleChange}
              onBlur={codeFormik.handleBlur}
            />
            {codeFormik.touched.code && codeFormik.errors.code && (
              <div className="text-red-500 font-bold mt-1">
                {codeFormik.errors.code}
              </div>
            )}
            <button
              type="submit"
              className="min-w-button font-bold text-white px-3 py-2.5 rounded-full background-primary mt-7"
            >
              Acceder
            </button>
          </form>
        </div>
      </div>
    );
  }

  if (!logInformation.loaded) {
    return (
      <div className="w-screen h-screen flex justify-center items-center bg-slate-50">
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  console.log(logInformation.data.response);

  return (
    <div className="w-full h-screen flex flex-col bg-slate-50">
      <div className="flex justify-between items-center px-16 py-8 primary-color">
        <div className="font-700-24">Detalle del Registro</div>
        <div
          className="font-bold border-primary dashboard bg-white rounded-lg px-4 py-2 ease-in duration-200 button-cta-admin cursor-pointer"
          onClick={backToDashboard}
        >
          Volver al Dashboard
        </div>
      </div>
      <div className="flex flex-col rounded-2xl border border-slate-300 dashboard bg-white mx-16 px-16 py-8 mb-12">
        <div className="border-b-2 border-slate-300 pb-6">
          <div className="mb-2">
            <span className="font-bold primary-color">Hubspot ID: </span>
            {logInformation.data.request.hubspotId}
          </div>
          <div className="mb-2">
            <span className="font-bold primary-color">Email: </span>
            {logInformation.data.request.email}
          </div>
          <div className="mb-2">
            <span className="font-bold primary-color">Proyecto: </span>
            {logInformation.data.cliente_name}
          </div>
          <div>
            <span className="font-bold primary-color">Fecha: </span>
            {new Date(logInformation.data.created_at).toLocaleDateString(
              "es-ES",
              {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              }
            )}
          </div>
        </div>
        <div className="border-b-2 border-slate-300 mt-6 pb-6">
          <div>
            <span className="font-bold primary-color">Resultado: </span>
            {!logInformation.data.response.error ? (
              <span className="bg-green-200 rounded-2xl px-3 primary-color font-bold ml-2">
                Finalizado
              </span>
            ) : (
              <span className="bg-red-200 rounded-2xl px-3 text-red-700 font-bold ml-2">
                No finalizado
              </span>
            )}
          </div>
        </div>
        <div className="mt-6">
          <div className="font-bold primary-color">Datos extraídos:</div>
          {logInformation.data.response.error ? (
            <div className="flex flex-row flex-wrap">
              <div className="text-red-500 w-full lg:w-3/6 2xl:w-2/6 pr-5 pt-4">
                {errorMessages[logInformation.data.response.error]}
              </div>
            </div>
          ) : (
            <div className="flex flex-row flex-wrap">
              {logInformation.data.response.userDataByProvider[0].identity && (
                <div className="w-full lg:w-3/6 2xl:w-2/6 pr-5 pt-4">
                  <div className="font-bold primary-color">Identidad</div>
                  {logInformation.data.response.userDataByProvider[0].identity
                    .name && (
                    <div>
                      <span className="font-bold">Nombre: </span>
                      <span>
                        {
                          logInformation.data.response.userDataByProvider[0]
                            .identity.name
                        }
                      </span>
                    </div>
                  )}
                  {logInformation.data.response.userDataByProvider[0].identity
                    .ssn && (
                    <div>
                      <span className="font-bold">SSN: </span>
                      <span>
                        {
                          logInformation.data.response.userDataByProvider[0]
                            .identity.ssn
                        }
                      </span>
                    </div>
                  )}
                  {logInformation.data.response.userDataByProvider[0].identity
                    .dateOfBirth && (
                    <div>
                      <span className="font-bold">Fecha de Nacimiento: </span>
                      <span>
                        {
                          logInformation.data.response.userDataByProvider[0]
                            .identity.dateOfBirth
                        }
                      </span>
                    </div>
                  )}
                </div>
              )}
              {logInformation.data.response.userDataByProvider[0].accounts && (
                <div className="w-full lg:w-3/6 2xl:w-2/6 pr-5 pt-4">
                  <div className="font-bold primary-color">Cuenta</div>
                  {logInformation.data.response.userDataByProvider[0]
                    .financialInstitutionName && (
                    <div>
                      <span className="font-bold">Banco: </span>
                      <span>
                        {
                          logInformation.data.response.userDataByProvider[0]
                            .financialInstitutionName
                        }
                      </span>
                    </div>
                  )}
                  {logInformation.data.response.userDataByProvider[0]
                    .accounts[0].iban && (
                    <div>
                      <span className="font-bold">IBAN: </span>
                      <span>
                        {
                          logInformation.data.response.userDataByProvider[0]
                            .accounts[0].iban
                        }
                      </span>
                    </div>
                  )}
                  {logInformation.data.response.userDataByProvider[0]
                    .accounts[0].accountIdentifiers.iban.bic && (
                    <div>
                      <span className="font-bold">Fecha de Nacimiento: </span>
                      <span>
                        {
                          logInformation.data.response.userDataByProvider[0]
                            .accounts[0].accountIdentifiers.iban.bic
                        }
                      </span>
                    </div>
                  )}
                  {logInformation.data.response.userDataByProvider[0]
                    .accounts[0].currencyCode && (
                    <div>
                      <span className="font-bold">Moneda: </span>
                      <span>
                        {
                          logInformation.data.response.userDataByProvider[0]
                            .accounts[0].currencyCode
                        }
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LogDetailTink;

// Libraries
import React from "react";
import { useSelector } from "react-redux";

function LogoPage() {
  const environmentData = useSelector((state) => state.environmentData);

  return (
    <div className="flex justify-center items-center w-screen h-full background-primary">
      <img
        className="w-8/12"
        alt="logo"
        src={`/assets/logos/${environmentData.business}/white.svg`}
      />
    </div>
  );
}

export default LogoPage;

// Libraries
import React from "react";
import { useSelector } from "react-redux";

// Hooks
import { useNavigation } from "../../hooks/useNavigation";

function Header({ index = "- / -", backURL }) {
  const environmentData = useSelector((state) => state.environmentData);
  const navigation = useNavigation();

  function backURLNavigation() {
    navigation(backURL);
  }

  return (
    <div className="w-screen flex justify-center items-center background-primary pt-4 pb-4">
      {backURL && (
        <div
          className="flex flex-row absolute left-8 text-white font-700-14 cursor-pointer"
          onClick={backURLNavigation}
        >
          <img alt="back-icon" src="/assets/icons/back-icon.svg" />
          <div className="ml-1">Volver</div>
        </div>
      )}
      <img
        className="w-24"
        alt="logo"
        src={`/assets/logos/${environmentData.business}/white.svg`}
      />
      <div className="absolute right-8 text-white font-700-14">{index}</div>
    </div>
  );
}

export default Header;

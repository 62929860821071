// Libraries
import React from "react";
import { useSelector } from "react-redux";

function ThankYou() {
  const environmentData = useSelector((state) => state.environmentData);

  return (
    <div className="flex flex-col justify-evenly items-center w-screen h-full pb-32">
      <img
        alt="logo"
        src={`/assets/logos/${environmentData.business}/primary.svg`}
      />
      <div className="flex flex-col justify-between items-center">
        <div className="primary-color font-700-24">¡Gracias!</div>
        <div className="font-400-15 text-secondary-color text-center w-9/12">
          Te informaremos si la verificación es válida.
        </div>
      </div>
    </div>
  );
}

export default ThankYou;

const initialState = {
  hubspotId: "",
  email: "",
  isFilled: false,
  isFinished: false,
  images: {
    frontDoc: "",
    backDoc: "",
    selfie: "",
  },
};

export default initialState;

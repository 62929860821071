import initialState from "./state";
import {
  SET_WHITELABEL_DATA,
  IMPORT_FRONT_DOC_IMAGE,
  IMPORT_BACK_DOC_IMAGE,
  IMPORT_SELFIE_IMAGE,
  SET_FINISHED,
} from "./types";

const globalDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WHITELABEL_DATA:
      return { ...state, ...action.payload };
    case IMPORT_FRONT_DOC_IMAGE:
      return {
        ...state,
        images: {
          frontDoc: action.payload,
          backDoc: state.images.backDoc,
          selfie: state.images.selfie,
        },
      };
    case IMPORT_BACK_DOC_IMAGE:
      return {
        ...state,
        images: {
          frontDoc: state.images.frontDoc,
          backDoc: action.payload,
          selfie: state.images.selfie,
        },
      };
    case IMPORT_SELFIE_IMAGE:
      return {
        ...state,
        images: {
          frontDoc: state.images.frontDoc,
          backDoc: state.images.backDoc,
          selfie: action.payload,
        },
      };
    case SET_FINISHED:
      return {
        ...state,
        isFinished: action.payload,
      };
    default:
      return state;
  }
};

export default globalDataReducer;

// Libraries
import React from "react";

function Button({ text, className, onClick }) {
  return (
    <button
      className={`w-10/12 height-48 background-secondary radius-40 primary-color font-700-22 cursor-pointer ${className}`}
      type="button"
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export default Button;

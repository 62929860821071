// Libraries
import React from "react";
import { useSelector } from "react-redux";

// Components
import Button from "../../components/Button";

// Hooks
import { useNavigation } from "../../hooks/useNavigation";

function Welcome() {
  const navigation = useNavigation();
  const environmentData = useSelector((state) => state.environmentData);

  function nextPage() {
    navigation("/front-advice");
  }

  return (
    <div className="flex flex-col justify-between items-center w-screen h-full pt-10 pb-6">
      <img
        alt="logo"
        src={`/assets/logos/${environmentData.business}/primary.svg`}
      />
      <div className="flex flex-col justify-between items-center">
        <img
          className=""
          alt="welcome-icon"
          src={`/assets/icons/${environmentData.business}/welcome-icon.svg`}
        />
        <div className="primary-color font-700-24 mt-4">
          Verifica tu identidad
        </div>
        <div className="font-400-15 text-secondary-color text-center w-9/12 mt-2">
          Por favor, sigue las instrucciones del proceso para validar tu
          identidad
        </div>
      </div>
      <Button text="Iniciar" onClick={nextPage} />
    </div>
  );
}

export default Welcome;

import initialState from "./state";
import { SET_ENVIRONMENT_DATA } from "./types";

const environmentDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ENVIRONMENT_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default environmentDataReducer;

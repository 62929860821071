import axios from "axios";

function getToken(business) {
  switch (business) {
    case "advancing":
      return process.env.REACT_APP_CLIENT_TOKEN_ADVANCING;
    case "menai":
      return process.env.REACT_APP_CLIENT_TOKEN_MENAI;
    default:
      return "no-token";
  }
}

export function sendToVendeptMitekMiddleware(objectData, business) {
  const clientToken = getToken(business);
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_VENDEPT_API_URL}/api/mitek/validation`,
    headers: {
      Authorization: `Bearer ${clientToken}`,
    },
    data: objectData,
  });
}

export function getContact(email, business) {
  const clientToken = getToken(business);
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_VENDEPT_API_URL}/api/mitek/get-contact`,
    headers: {
      Authorization: `Bearer ${clientToken}`,
    },
    data: {
      email: email,
    },
  });
}

export function getLogsPagination(page, business) {
  const clientToken = getToken(business);
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_VENDEPT_API_URL}/api/mitek/get-logs-pagination/${page}`,
    headers: {
      Authorization: `Bearer ${clientToken}`,
    },
  });
}

export function getLogsPaginationTink(page, business) {
  const clientToken = getToken(business);
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_VENDEPT_API_URL}/api/tink/get-logs-pagination/${page}`,
    headers: {
      Authorization: `Bearer ${clientToken}`,
    },
  });
}

export function getLogById(id, business) {
  const clientToken = getToken(business);
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_VENDEPT_API_URL}/api/mitek/get-log/${id}`,
    headers: {
      Authorization: `Bearer ${clientToken}`,
    },
  });
}

export function getLogByIdTink(id, business) {
  const clientToken = getToken(business);
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_VENDEPT_API_URL}/api/tink/get-log/${id}`,
    headers: {
      Authorization: `Bearer ${clientToken}`,
    },
  });
}

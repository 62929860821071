import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { getLogById } from "../../services/api/apiHandler";
import { setCode } from "../../redux/isCodeOk/actions";

// Squema
const codeSquema = Yup.object().shape({
  code: Yup.string()
    .equals([process.env.REACT_APP_CODE], "Código incorrecto")
    .required("Este campo es obligatorio"),
});

function LogDetail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state: locationState } = useLocation();
  const { logId } = useParams();
  const isCodeOk = useSelector((state) => state.isCodeOk);
  const environmentData = useSelector((state) => state.environmentData);
  const [logInformation, setLogInformation] = useState({
    loaded: false,
    data: [],
  });
  const [document, setDocument] = useState("");
  const [face, setFace] = useState("");

  const codeFormik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: codeSquema,
    onSubmit: (values, { resetForm }) => {
      resetForm();
      dispatch(setCode(true));
      getLogById(logId, environmentData.business)
        .then(({ data }) => {
          setLogInformation({ loaded: true, data: data });
          // Document state
          if (data.error) {
            setDocument("ERROR");
          } else if (data.retrievalVerify) {
            if (data.retrievalVerify.acceptance.result === "ACCEPTED") {
              if (
                data.retrievalVerify.findings.documentApproval === "APPROVED"
              ) {
                setDocument("APPROVED");
              } else {
                setDocument("NOT_APPROVED");
              }
            } else {
              setDocument("REJECTED");
            }
          } else {
            setDocument("NULL");
          }
          // Face state
          if (data.retrievalFaceComparison.approval === "APPROVED") {
            setFace("APPROVED");
          } else {
            setFace("NOT_APPROVED");
          }
        })
        .catch((error) => {
          if (error.response.data.isEmpty) {
            alert("No existe este registro");
            navigate("/admin?service=mitek");
          } else {
            alert("Error al tomar los datos del servidor");
            console.log(error);
          }
        });
    },
  });

  function backToDashboard() {
    if (locationState) {
      navigate(
        `/admin?dashboard_page=${locationState.pageToBack}&service=mitek`
      );
    } else {
      navigate(`/admin?service=mitek`);
    }
  }

  useEffect(() => {
    let isSubscribed = true;

    if (isCodeOk) {
      getLogById(logId, environmentData.business)
        .then(({ data }) => {
          if (isSubscribed) {
            setLogInformation({ loaded: true, data: data });
            // Document state
            if (data.error) {
              setDocument("ERROR");
            } else if (data.retrievalVerify) {
              if (data.retrievalVerify.acceptance.result === "ACCEPTED") {
                if (
                  data.retrievalVerify.findings.documentApproval === "APPROVED"
                ) {
                  setDocument("APPROVED");
                } else {
                  setDocument("NOT_APPROVED");
                }
              } else {
                setDocument("REJECTED");
              }
            } else {
              setDocument("NULL");
            }
            // Face state
            if (data.retrievalFaceComparison.approval === "APPROVED") {
              setFace("APPROVED");
            } else {
              setFace("NOT_APPROVED");
            }
          }
        })
        .catch((error) => {
          if (isSubscribed) {
            if (error.response.data.isEmpty) {
              alert("No existe este registro");
              navigate("/admin?service=mitek");
            } else {
              alert("Error al tomar los datos del servidor");
              console.log(error);
            }
          }
        });
    }

    return () => (isSubscribed = false);
    // eslint-disable-next-line
  }, []);

  if (!isCodeOk) {
    return (
      <div className="w-screen h-screen flex flex-col items-center bg-slate-50">
        <div className="w-72 mt-16 mb-16">
          <img
            className="w-full"
            alt="logo"
            src={`/assets/logos/${environmentData.business}/primary.svg`}
          />
        </div>
        <div className="flex flex-col items-center px-20 py-14 rounded-3xl border border-slate-300 login-box bg-white">
          <h1 className="font-700-22 primary-color mb-7">
            Introduce el código de acceso
          </h1>
          <form
            className="flex flex-col items-center w-full"
            onSubmit={codeFormik.handleSubmit}
          >
            <input
              type="password"
              name="code"
              id="code"
              value={codeFormik.values.code}
              placeholder="Código"
              className={
                codeFormik.touched.code && codeFormik.errors.code
                  ? "border-2 px-3 py-2 leading-4 rounded-full text-base email-input w-full input-incorrect"
                  : "border-2 px-3 py-2 leading-4 rounded-full text-base email-input w-full"
              }
              onChange={codeFormik.handleChange}
              onBlur={codeFormik.handleBlur}
            />
            {codeFormik.touched.code && codeFormik.errors.code && (
              <div className="text-red-500 font-bold mt-1">
                {codeFormik.errors.code}
              </div>
            )}
            <button
              type="submit"
              className="min-w-button font-bold text-white px-3 py-2.5 rounded-full background-primary mt-7"
            >
              Acceder
            </button>
          </form>
        </div>
      </div>
    );
  }

  if (!logInformation.loaded) {
    return (
      <div className="w-screen h-screen flex justify-center items-center bg-slate-50">
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-screen flex flex-col bg-slate-50">
      <div className="flex justify-between items-center px-16 py-8 primary-color">
        <div className="font-700-24">Detalle del Registro</div>
        <div
          className="font-bold border-primary dashboard bg-white rounded-lg px-4 py-2 ease-in duration-200 button-cta-admin cursor-pointer"
          onClick={backToDashboard}
        >
          Volver al Dashboard
        </div>
      </div>
      <div className="flex flex-col rounded-2xl border border-slate-300 dashboard bg-white mx-16 px-16 py-8 mb-12">
        <div className="border-b-2 border-slate-300 pb-6">
          <div className="mb-2">
            <span className="font-bold primary-color">Hubspot ID: </span>
            {logInformation.data.request.hubspotId}
          </div>
          <div className="mb-2">
            <span className="font-bold primary-color">Email: </span>
            {logInformation.data.request.email}
          </div>
          <div className="mb-2">
            <span className="font-bold primary-color">Proyecto: </span>
            {logInformation.data.client_name}
          </div>
          <div>
            <span className="font-bold primary-color">Fecha: </span>
            {new Date(logInformation.data.created_at).toLocaleDateString(
              "es-ES",
              {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              }
            )}
          </div>
        </div>
        <div className="border-b-2 border-slate-300 mt-6 pb-6">
          <div className="mb-2">
            <span className="font-bold primary-color">
              Estado de validación:{" "}
            </span>
            {logInformation.data.status === "In process" && (
              <span className="bg-slate-200 rounded-2xl px-3 text-black font-bold ml-2">
                En proceso
              </span>
            )}
            {logInformation.data.status === "Finished" && (
              <span className="bg-green-200 rounded-2xl px-3 primary-color font-bold ml-2">
                Terminado
              </span>
            )}
            {logInformation.data.status === "Not finished" && (
              <span className="bg-red-200 rounded-2xl px-3 text-red-700 font-bold ml-2">
                No terminado
              </span>
            )}
          </div>
          <div className="mb-2">
            <span className="font-bold primary-color">
              Resultado Comparación Facial:{" "}
            </span>
            {face === "APPROVED" ? (
              <span className="bg-green-200 rounded-2xl px-3 primary-color font-bold ml-2">
                Aprobado
              </span>
            ) : (
              <span className="bg-red-200 rounded-2xl px-3 text-red-700 font-bold ml-2">
                No aprobado
              </span>
            )}
          </div>
          <div>
            <span className="font-bold primary-color">
              Resultado Documento:{" "}
            </span>
            {document === "ERROR" && (
              <span className="bg-red-200 rounded-2xl px-3 text-red-700 font-bold ml-2">
                Error
              </span>
            )}
            {document === "NULL" && (
              <span className="bg-slate-200 rounded-2xl px-3 text-black font-bold ml-2">
                Pendiente
              </span>
            )}
            {document === "APPROVED" && (
              <span className="bg-green-200 rounded-2xl px-3 primary-color font-bold ml-2">
                Aprobado
              </span>
            )}
            {document === "NOT_APPROVED" && (
              <span className="bg-red-200 rounded-2xl px-3 text-red-700 font-bold ml-2">
                No aprobado
              </span>
            )}
            {document === "REJECTED" && (
              <span className="bg-red-200 rounded-2xl px-3 text-red-700 font-bold ml-2">
                Rechazado
              </span>
            )}
          </div>
        </div>
        <div className="border-b-2 border-slate-300 mt-6 pb-6">
          <div className="font-bold primary-color">Imágenes del documento:</div>
          <div className="flex flex-row justify-around my-4">
            <div className="max-w-md mr-5">
              <img
                className="object-contain w-full"
                alt="front-doc"
                src={`data:image/jpeg;base64,${logInformation.data.request.frontDocImage}`}
              />
            </div>
            <div className="max-w-md">
              <img
                className="object-contain w-full"
                alt="front-doc"
                src={`data:image/jpeg;base64,${logInformation.data.request.backDocImage}`}
              />
            </div>
          </div>
        </div>
        <div className="border-b-2 border-slate-300 mt-6 pb-6">
          <div className="font-bold primary-color">
            Imagen de la prueba de vida:
          </div>
          <div className="flex flex-row justify-around my-4">
            <div className="max-w-xs">
              <img
                className="object-contain w-full"
                alt="front-doc"
                src={`data:image/jpeg;base64,${logInformation.data.request.selfieImage}`}
              />
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="font-bold primary-color">Datos extraídos:</div>
          {document === "ERROR" && (
            <div className="pt-4">
              Un{" "}
              <span className="text-red-500 font-bold">error inesperado</span>{" "}
              ha ocurrido con este registro.
            </div>
          )}
          {document === "NULL" && (
            <div className="pt-4">Pendiente de recibir la validación ...</div>
          )}
          {document === "REJECTED" && (
            <div className="pt-4">
              <div>
                El documento fue{" "}
                <span className="text-red-500 font-bold">rechazado</span> por
                las siguientes razones:
              </div>
              {logInformation.data.retrievalVerify.acceptance.reasons.map(
                (reason, index) => (
                  <div key={index}>{`- ${reason}`}</div>
                )
              )}
            </div>
          )}
          {(document === "NOT_APPROVED" || document === "APPROVED") && (
            <div className="flex flex-row flex-wrap">
              <div className="w-full lg:w-3/6 2xl:w-2/6 pr-5 pt-4">
                <div className="font-bold primary-color">Aprobación</div>
                <div>
                  <span className="font-bold">Autenticidad: </span>
                  <span
                    className={
                      logInformation.data.retrievalVerify.findings.details
                        .authenticity.result === "NOT_AUTHENTIC"
                        ? "font-bold text-red-500"
                        : ""
                    }
                  >
                    {
                      logInformation.data.retrievalVerify.findings.details
                        .authenticity.result
                    }
                  </span>
                </div>
                {logInformation.data.retrievalVerify.findings.details
                  .authenticity.reasons && (
                  <>
                    <div className="font-bold">Razones:</div>
                    {logInformation.data.retrievalVerify.findings.details.authenticity.reasons.map(
                      (reason, index) => (
                        <div key={index}>{`- ${reason}`}</div>
                      )
                    )}
                  </>
                )}
                <div>
                  <span className="font-bold">Originalidad: </span>
                  <span
                    className={
                      logInformation.data.retrievalVerify.findings.details
                        .originality.result === "NOT_ORIGINAL"
                        ? "font-bold text-red-500"
                        : ""
                    }
                  >
                    {
                      logInformation.data.retrievalVerify.findings.details
                        .originality.result
                    }
                  </span>
                </div>
                {logInformation.data.retrievalVerify.findings.details
                  .originality.reasons && (
                  <>
                    <div className="font-bold">Razones:</div>
                    {logInformation.data.retrievalVerify.findings.details.originality.reasons.map(
                      (reason, index) => (
                        <div key={index}>{`- ${reason}`}</div>
                      )
                    )}
                  </>
                )}
                <div>
                  <span className="font-bold">Validación: </span>
                  <span
                    className={
                      logInformation.data.retrievalVerify.findings.details
                        .validity.result === "NOT_VALID"
                        ? "font-bold text-red-500"
                        : ""
                    }
                  >
                    {
                      logInformation.data.retrievalVerify.findings.details
                        .validity.result
                    }
                  </span>
                </div>
                {logInformation.data.retrievalVerify.findings.details.validity
                  .reasons && (
                  <>
                    <div className="font-bold">Razones:</div>
                    {logInformation.data.retrievalVerify.findings.details.validity.reasons.map(
                      (reason, index) => (
                        <div key={index}>{`- ${reason}`}</div>
                      )
                    )}
                  </>
                )}
              </div>
              <div className="w-full lg:w-3/6 2xl:w-2/6 pr-5 pt-4">
                <div className="font-bold primary-color">
                  Atributos del documento
                </div>
                {logInformation.data.retrievalVerify.extractedData
                  .documentAttributes.countryCodeOfIssuance && (
                  <div>
                    <span className="font-bold">
                      Código del país de emisión:{" "}
                    </span>
                    {
                      logInformation.data.retrievalVerify.extractedData
                        .documentAttributes.countryCodeOfIssuance
                    }
                  </div>
                )}
                {logInformation.data.retrievalVerify.extractedData
                  .documentAttributes.dateOfExpiry && (
                  <div>
                    <span className="font-bold">Fecha de caducidad: </span>
                    {
                      logInformation.data.retrievalVerify.extractedData
                        .documentAttributes.dateOfExpiry
                    }
                  </div>
                )}
                {logInformation.data.retrievalVerify.extractedData
                  .documentAttributes.dateOfIssuance && (
                  <div>
                    <span className="font-bold">Fecha de emisión: </span>
                    {
                      logInformation.data.retrievalVerify.extractedData
                        .documentAttributes.dateOfIssuance
                    }
                  </div>
                )}
                {logInformation.data.retrievalVerify.extractedData
                  .documentAttributes.documentNumber && (
                  <div>
                    <span className="font-bold">Número de documento: </span>
                    {
                      logInformation.data.retrievalVerify.extractedData
                        .documentAttributes.documentNumber
                    }
                  </div>
                )}
                {logInformation.data.retrievalVerify.extractedData
                  .documentAttributes.documentType && (
                  <div>
                    <span className="font-bold">Tipo de documento: </span>
                    {
                      logInformation.data.retrievalVerify.extractedData
                        .documentAttributes.documentType
                    }
                  </div>
                )}
                {logInformation.data.retrievalVerify.extractedData
                  .documentAttributes.documentSubType && (
                  <div>
                    <span className="font-bold">Subtipo de documento: </span>
                    {
                      logInformation.data.retrievalVerify.extractedData
                        .documentAttributes.documentSubType
                    }
                  </div>
                )}
                {logInformation.data.retrievalVerify.extractedData
                  .documentAttributes.issuingAuthority && (
                  <div>
                    <span className="font-bold">Autoridad emisora: </span>
                    {
                      logInformation.data.retrievalVerify.extractedData
                        .documentAttributes.issuingAuthority
                    }
                  </div>
                )}
                {logInformation.data.retrievalVerify.extractedData
                  .documentAttributes.stateOrProvinceOfIssuance && (
                  <div>
                    <span className="font-bold">
                      Estado o provincia de origen:{" "}
                    </span>
                    {
                      logInformation.data.retrievalVerify.extractedData
                        .documentAttributes.stateOrProvinceOfIssuance
                    }
                  </div>
                )}
              </div>
              <div className="w-full lg:w-3/6 2xl:w-2/6 pr-5 pt-4">
                <div className="font-bold primary-color">
                  Atributos de la persona
                </div>
                {logInformation.data.retrievalVerify.extractedData
                  .personAttributes.dateOfBirth && (
                  <div>
                    <span className="font-bold">Fecha de nacimiento: </span>
                    {
                      logInformation.data.retrievalVerify.extractedData
                        .personAttributes.dateOfBirth
                    }
                  </div>
                )}
                {logInformation.data.retrievalVerify.extractedData
                  .personAttributes.gender && (
                  <div>
                    <span className="font-bold">Género: </span>
                    {
                      logInformation.data.retrievalVerify.extractedData
                        .personAttributes.gender
                    }
                  </div>
                )}
                {logInformation.data.retrievalVerify.extractedData
                  .personAttributes.nationalityCode && (
                  <div>
                    <span className="font-bold">Código de nacionalidad: </span>
                    {
                      logInformation.data.retrievalVerify.extractedData
                        .personAttributes.nationalityCode
                    }
                  </div>
                )}
                {logInformation.data.retrievalVerify.extractedData
                  .personAttributes.personalGovernmentId && (
                  <div>
                    <span className="font-bold">Identificación personal: </span>
                    {
                      logInformation.data.retrievalVerify.extractedData
                        .personAttributes.personalGovernmentId
                    }
                  </div>
                )}
                {logInformation.data.retrievalVerify.extractedData
                  .personAttributes.placeOfBirth && (
                  <div>
                    <span className="font-bold">Lugar de nacimiento: </span>
                    {
                      logInformation.data.retrievalVerify.extractedData
                        .personAttributes.placeOfBirth
                    }
                  </div>
                )}
              </div>
              <div className="w-full lg:w-3/6 2xl:w-2/6 pr-5 pt-4">
                <div className="font-bold primary-color">Nombre</div>
                {logInformation.data.retrievalVerify.extractedData.name
                  .fullName && (
                  <div>
                    <span className="font-bold">Nombre completo: </span>
                    {
                      logInformation.data.retrievalVerify.extractedData.name
                        .fullName
                    }
                  </div>
                )}
                {logInformation.data.retrievalVerify.extractedData.name
                  .givenNames && (
                  <div>
                    <span className="font-bold">Nombres de pila: </span>
                    {
                      logInformation.data.retrievalVerify.extractedData.name
                        .givenNames
                    }
                  </div>
                )}
                {logInformation.data.retrievalVerify.extractedData.name
                  .surname && (
                  <div>
                    <span className="font-bold">Apellido: </span>
                    {
                      logInformation.data.retrievalVerify.extractedData.name
                        .surname
                    }
                  </div>
                )}
                {logInformation.data.retrievalVerify.extractedData.name
                  .firstSurname && (
                  <div>
                    <span className="font-bold">Primer apellido: </span>
                    {
                      logInformation.data.retrievalVerify.extractedData.name
                        .firstSurname
                    }
                  </div>
                )}
                {logInformation.data.retrievalVerify.extractedData.name
                  .secondSurname && (
                  <div>
                    <span className="font-bold">Segundo apellido: </span>
                    {
                      logInformation.data.retrievalVerify.extractedData.name
                        .secondSurname
                    }
                  </div>
                )}
              </div>
              {logInformation.data.retrievalVerify.extractedData.address && (
                <div className="w-full 2xl:w-2/6 pr-5 pt-4">
                  <div className="font-bold primary-color">Dirección</div>
                  {logInformation.data.retrievalVerify.extractedData.address
                    .parsedAddress ? (
                    <>
                      {logInformation.data.retrievalVerify.extractedData.address
                        .parsedAddress.addressLine1 && (
                        <div>
                          <span className="font-bold">Calle Línea 1: </span>
                          {
                            logInformation.data.retrievalVerify.extractedData
                              .address.parsedAddress.addressLine1
                          }
                        </div>
                      )}
                      {logInformation.data.retrievalVerify.extractedData.address
                        .parsedAddress.addressLine2 && (
                        <div>
                          <span className="font-bold">Calle Línea 2: </span>
                          {
                            logInformation.data.retrievalVerify.extractedData
                              .address.parsedAddress.addressLine2
                          }
                        </div>
                      )}
                      {logInformation.data.retrievalVerify.extractedData.address
                        .parsedAddress.addressLine3 && (
                        <div>
                          <span className="font-bold">Calle Línea 3: </span>
                          {
                            logInformation.data.retrievalVerify.extractedData
                              .address.parsedAddress.addressLine3
                          }
                        </div>
                      )}
                      {logInformation.data.retrievalVerify.extractedData.address
                        .parsedAddress.addressLine4 && (
                        <div>
                          <span className="font-bold">Calle Línea 4: </span>
                          {
                            logInformation.data.retrievalVerify.extractedData
                              .address.parsedAddress.addressLine4
                          }
                        </div>
                      )}
                      {logInformation.data.retrievalVerify.extractedData.address
                        .parsedAddress.city && (
                        <div>
                          <span className="font-bold">Ciudad: </span>
                          {
                            logInformation.data.retrievalVerify.extractedData
                              .address.parsedAddress.city
                          }
                        </div>
                      )}
                      {logInformation.data.retrievalVerify.extractedData.address
                        .parsedAddress.postalCode && (
                        <div>
                          <span className="font-bold">Código postal: </span>
                          {
                            logInformation.data.retrievalVerify.extractedData
                              .address.parsedAddress.postalCode
                          }
                        </div>
                      )}
                      {logInformation.data.retrievalVerify.extractedData.address
                        .parsedAddress.stateOrProvince && (
                        <div>
                          <span className="font-bold">
                            Estado o provincia:{" "}
                          </span>
                          {
                            logInformation.data.retrievalVerify.extractedData
                              .address.parsedAddress.stateOrProvince
                          }
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {logInformation.data.retrievalVerify.extractedData.address
                        .unparsedAddress.addressLine1 && (
                        <div>
                          <span className="font-bold">Calle Línea 1: </span>
                          {
                            logInformation.data.retrievalVerify.extractedData
                              .address.unparsedAddress.addressLine1
                          }
                        </div>
                      )}
                      {logInformation.data.retrievalVerify.extractedData.address
                        .unparsedAddress.addressLine2 && (
                        <div>
                          <span className="font-bold">Calle Línea 2: </span>
                          {
                            logInformation.data.retrievalVerify.extractedData
                              .address.unparsedAddress.addressLine2
                          }
                        </div>
                      )}
                      {logInformation.data.retrievalVerify.extractedData.address
                        .unparsedAddress.addressLine3 && (
                        <div>
                          <span className="font-bold">Calle Línea 3: </span>
                          {
                            logInformation.data.retrievalVerify.extractedData
                              .address.unparsedAddress.addressLine3
                          }
                        </div>
                      )}
                      {logInformation.data.retrievalVerify.extractedData.address
                        .unparsedAddress.addressLine4 && (
                        <div>
                          <span className="font-bold">Calle Línea 4: </span>
                          {
                            logInformation.data.retrievalVerify.extractedData
                              .address.unparsedAddress.addressLine4
                          }
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LogDetail;
